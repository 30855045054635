import { mapActions, mapGetters } from 'vuex'
import { MaxColors } from '@/helper/colorsPalette'
import {
  CustomDatePicker,
  SelectMenu
} from '@/components'

export default {
  components: {
    CustomDatePicker,
    SelectMenu
  },
  data () {
    return {
      datePickerDataGeneralSystem: {},
      datePickerDataTimeIteration: {},
      isLoading: false,
      colors: MaxColors.slice(0, 20),
      qdtInfosGraphic: [
        {
          id: 1,
          name: 'Top 10'
        },
        {
          id: 2,
          name: 'Top 20'
        }
      ],
      selectQtd: 1,
      graphicSystem: [],
      graphicTimeIteration: [],
      graphicExpressionForIntentions: [],
      width: 0
    }
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot'
    ]),
    globalColor () {
      const data = {
        parent: {
          color: this.$vuetify.theme.currentTheme.globalColor
        }
      }
      return data
    }
  },
  watch: {
    datePickerDataGeneralSystem () {
      this.getAnalyticGeralSystem()
    },
    datePickerDataTimeIteration () {
      this.getAnalyticTimeIteration()
    }
  },
  created () {
    this.getAnalyticGeralExpressionForIntentions()
  },
  mounted () {
    this.width = document.querySelectorAll('.graphic-board')[2].offsetWidth - 17
    window.addEventListener('resize', (win) => {
      this.width = win.srcElement.document.querySelectorAll('.graphic-board')[2].offsetWidth - 17
    })
  },
  methods: {
    ...mapActions([
      'actionAnalyticGeralExpressionForIntentions',
      'actionAnalyticGeralSystem',
      'actionAnalyticTimeIteration'
    ]),
    getAnalyticGeralExpressionForIntentions () {
      this.isLoading = true
      this.graphicExpressionForIntentions = []
      const params = {
        selectQtd: this.selectQtd
      }
      this.actionAnalyticGeralExpressionForIntentions(params)
        .then(response => {
          this.graphicExpressionForIntentions.push({
            type: 'donut',
            width: 660,
            isTotal: false,
            series: response.series[0].data[0].values,
            labels: response.labels[0].values,
            colors: this.colors,
            legends: []
          })
          this.isLoading = false
        })
    },
    getAnalyticGeralSystem () {
      if (!Object.prototype.hasOwnProperty.call(this.datePickerDataGeneralSystem, 'startDate') || !Object.prototype.hasOwnProperty.call(this.datePickerDataGeneralSystem, 'endDate')) {
        return
      }

      this.isLoading = true
      this.graphicSystem = []
      const params = {
        ...this.datePickerDataGeneralSystem
      }
      this.actionAnalyticGeralSystem(params)
        .then(response => {
          this.graphicSystem.push({
            type: 'donut',
            width: 660,
            isTotal: false,
            series: response.series[0].data[0].values,
            labels: response.labels[0].values,
            colors: this.colors,
            legends: [
              {
                text: 'Total de Horas Estabilidade:',
                value: 950
              },
              {
                text: 'Total de Horas Instabilidade:',
                value: 150
              }
            ]
          })
          this.isLoading = false
        })
    },
    getAnalyticTimeIteration () {
      this.isLoading = true
      this.graphicTimeIteration = []
      const params = {
        ...this.datePickerDataTimeIteration
      }
      this.actionAnalyticTimeIteration(params)
        .then(response => {
          const graphic = {
            type: response.labels[0].values[0].includes('/') ? 'bar' : 'area',
            horizontal: true,
            width: '100%',
            series: [],
            labels: response.labels[0].values,
            colors: this.colors,
            legends: []
          }
          response.series.forEach(item => {
            graphic.series.push({
              name: item.name,
              data: item.data[0].values
            })
          })
          this.graphicTimeIteration.push(graphic)
          this.isLoading = false
        })
    },
    selectQtdInfos (item) {
      if (item) {
        this.selectQtd = item
        this.getAnalyticGeralExpressionForIntentions()
      }
    }
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    staticClass: "d-flex flex-column px-10",
    attrs: {
      "id": "page-dashboard"
    }
  }, [_c('div', {
    staticClass: "container-dash-general"
  }, [_c('v-row', {
    staticStyle: {
      "max-width": "1400px"
    }
  }, [_c('v-col', {
    staticClass: "header px-10 pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('section', {
    staticClass: "line d-flex justify-content justify-center"
  }, [_c('div', {
    staticClass: "bot"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('div', {
    staticClass: "container-logo-bot",
    style: {
      'borderColor': _vm.getterSelectedBot.botColor
    }
  }, [_c('div', {
    staticClass: "logo-bot",
    style: {
      'backgroundColor': _vm.getterSelectedBot.botColor
    }
  }, [_vm.getterSelectedBot.hasOwnProperty('logo') && _vm.getterSelectedBot.logo ? _c('img', {
    attrs: {
      "src": _vm.getterSelectedBot.logo
    }
  }) : _c('img', {
    staticClass: "no-bot-logo",
    attrs: {
      "src": "/img/icon-bot.svg"
    }
  }), _c('span', {
    staticClass: "version",
    class: {
      'show-version': _vm.getterSelectedBot.hasOwnProperty('botVersions') && _vm.getterSelectedBot.botVersions && _vm.getterSelectedBot.botVersions[0].name !== null
    }
  }, [_vm._v(" " + _vm._s(_vm.getterSelectedBot.botVersions && _vm.getterSelectedBot.botVersions[0].name ? _vm.getterSelectedBot.botVersions[0].name : '') + " ")])])])]), _c('div', {
    staticClass: "info-bot"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(" Criado em 12/12/2012 ")]), _c('div', {
    staticClass: "text"
  }, [_c('strong', [_vm._v("Último terinamento em 12/12/2012")])]), _c('div', {
    staticClass: "text"
  }, [_c('strong', [_vm._v("Última publicação em 12/12/2012")])])])])])]), _c('v-col', {
    staticClass: "px-1 py-0 info-board d-flex justify-space-between",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "py-6 mx-1 card"
  }, [_c('div', {
    staticClass: "info-qtd",
    style: _vm.globalColor.parent
  }, [_vm._v(" 147 ")]), _c('div', {
    staticClass: "info-name pt-3"
  }, [_vm._v(" intenções ")])]), _c('v-card', {
    staticClass: "py-6 mx-1 card"
  }, [_c('div', {
    staticClass: "info-qtd",
    style: _vm.globalColor.parent
  }, [_vm._v(" 32 ")]), _c('div', {
    staticClass: "info-name pt-3"
  }, [_vm._v(" entidades ")])]), _c('v-card', {
    staticClass: "py-6 mx-1 card"
  }, [_c('div', {
    staticClass: "info-qtd",
    style: _vm.globalColor.parent
  }, [_vm._v(" 498 ")]), _c('div', {
    staticClass: "info-name pt-3"
  }, [_vm._v(" expressões ")])]), _c('v-card', {
    staticClass: "py-6 mx-1 card"
  }, [_c('div', {
    staticClass: "info-qtd",
    style: _vm.globalColor.parent
  }, [_vm._v(" 17 ")]), _c('div', {
    staticClass: "info-name pt-3"
  }, [_vm._v(" recursos globais ")])])], 1), _c('v-col', {
    staticClass: "px-1 py-1 d-flex justify-space-between",
    staticStyle: {
      "flex-wrap": "wrap"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('section', {
    staticClass: "ma-1 graphic-board"
  }, [_c('div', {
    staticClass: "graphic-name py-4"
  }, [_vm._v(" Quantidade de expressões por intenção ")]), _c('div', {
    staticClass: "select-period d-flex justify-center"
  }, [_c('span', {
    staticClass: "text-select-period mr-2"
  }, [_vm._v(" Quantidade de Dados: ")]), _c('p-select', {
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "item-text": "name",
      "item-value": "id",
      "items": _vm.qdtInfosGraphic,
      "height": 22,
      "set-selected": _vm.qdtInfosGraphic[0].id
    },
    on: {
      "selected": _vm.selectQtdInfos
    }
  })], 1), _vm.graphicExpressionForIntentions.length ? _c('p-chart', {
    attrs: {
      "type": _vm.graphicExpressionForIntentions[0].type,
      "width": _vm.graphicExpressionForIntentions[0].width,
      "series": _vm.graphicExpressionForIntentions[0].series,
      "labels": _vm.graphicExpressionForIntentions[0].labels,
      "colors": _vm.colors,
      "legends": _vm.graphicExpressionForIntentions[0].legends,
      "is-total": _vm.graphicExpressionForIntentions[0].isTotal,
      "responsive": 1,
      "max-width": 660
    }
  }) : _vm._e()], 1), _c('section', {
    staticClass: "ma-1 graphic-board"
  }, [_c('div', {
    staticClass: "graphic-name py-4"
  }, [_vm._v(" disponibilidade do sistema ")]), _c('div', {
    staticClass: "select-period d-flex justify-center align-center"
  }, [_c('span', {
    staticClass: "text-select-period mr-2"
  }, [_vm._v(" Selecione o período: ")]), _c('customDatePicker', {
    on: {
      "callback": function callback(data) {
        return _vm.datePickerDataGeneralSystem = data;
      }
    }
  })], 1), _vm.graphicSystem.length ? _c('p-chart', {
    attrs: {
      "type": _vm.graphicSystem[0].type,
      "width": _vm.graphicSystem[0].width,
      "series": _vm.graphicSystem[0].series,
      "labels": _vm.graphicSystem[0].labels,
      "colors": _vm.colors,
      "legends": _vm.graphicSystem[0].legends,
      "is-total": _vm.graphicSystem[0].isTotal,
      "responsive": 1,
      "max-width": 660
    }
  }) : _vm._e()], 1), _c('section', {
    staticClass: "ma-1 graphic-board graphic-line"
  }, [_c('div', {
    staticClass: "graphic-name py-4"
  }, [_vm._v(" tempo médio de interação ")]), _c('div', {
    staticClass: "select-period d-flex justify-center"
  }, [_c('span', {
    staticClass: "text-select-period mr-2"
  }, [_vm._v(" Selecione o período: ")]), _c('customDatePicker', {
    on: {
      "callback": function callback(data) {
        return _vm.datePickerDataTimeIteration = data;
      }
    }
  })], 1), _vm.graphicTimeIteration.length ? _c('p-chart', {
    attrs: {
      "type": _vm.graphicTimeIteration[0].type,
      "width": _vm.graphicTimeIteration[0].width,
      "series": _vm.graphicTimeIteration[0].series,
      "labels": _vm.graphicTimeIteration[0].labels,
      "colors": _vm.colors,
      "legends": _vm.graphicTimeIteration[0].legends,
      "is-total": _vm.graphicTimeIteration[0].isTotal,
      "responsive": 1400,
      "max-width": _vm.width
    }
  }) : _vm._e()], 1)])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }